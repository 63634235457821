<template>
  <q-table flat bordered :data="creative" row-key="id" :pagination="pagination" no-data-label="Selecione um canal" grid>
    <template v-slot:no-data="{ icon, message, filter }">
      <div class="full-width row flex-center text-accent q-gutter-sm">
        <q-icon size="3em" :name="filter ? 'filter_b_and_w' : icon" />
        <span class="text-h5">{{ message }}</span>
      </div>
    </template>
    <template v-slot:item="props">
      <div class="q-pa-sm col-xs-12 col-sm-6 col-md-6 col-lg-3">
        <q-card flat bordered class="q-pa-sm">
          <q-card-section class="bg-grey-3">
            {{ props.row.type_description }}
          </q-card-section>
          <q-card-section>
            <q-img
              :src="props.row.image_campaign_url"
              height="200px"
              contain
              v-if="['coupon', 'hyperlink', 'deal'].includes(props.row.type_name) && props.row.image_campaign_url"
            >
              <template v-slot:error>
                <div class="absolute-full flex flex-center bg-negative text-white">Cannot load image</div>
              </template>
            </q-img>
            <q-img
              :src="props.row.image_url"
              height="200px"
              contain
              v-else-if="props.row.type_name == 'image' && props.row.image_url"
            >
              <template v-slot:error>
                <div class="absolute-full flex flex-center bg-negative text-white">Cannot load image</div>
              </template>
            </q-img>
            <q-avatar
              v-else
              rounded
              class="full-width"
              style="height: 200px"
              font-size="100px"
              :color="!$q.dark.isActive ? 'grey-2' : 'dark'"
              :text-color="!$q.dark.isActive ? 'grey-5' : 'white'"
              :icon="getIconType(props.row.type_name)"
            />
          </q-card-section>
          <q-card-section class="q-pa-none">
            <q-list dense>
              <q-item>
                <q-item-section>
                  <q-item-label class="text-weight-bold">{{ $t('ID') }}: </q-item-label>
                </q-item-section>
                <q-item-section>
                  <q-item-label class="text-weight-bold text-positive">{{ props.row.id }} </q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <q-item-label class="text-weight-bold">{{ $t('campaign') }}: </q-item-label>
                </q-item-section>
                <q-item-section>
                  <q-item-label>{{ props.row.campaign_name }} ({{ props.row.id_campaign }}) </q-item-label>
                </q-item-section>
              </q-item>
              <q-item
                v-if="
                  props.row.type_name == 'coupon' || props.row.type_name == 'deal' || props.row.type_name == 'hyperlink'
                "
              >
                <q-item-section>
                  <q-item-label class="text-weight-bold">{{ $t('title') }}: </q-item-label>
                </q-item-section>
                <q-item-section>
                  <q-item-label>{{ props.row.title }}</q-item-label>
                </q-item-section>
              </q-item>
              <q-item
                v-if="
                  props.row.type_name == 'coupon' || props.row.type_name == 'deal' || props.row.type_name == 'hyperlink'
                "
              >
                <q-item-section>
                  <q-item-label class="text-weight-bold">{{ $t('description') }}: </q-item-label>
                </q-item-section>
                <q-item-section>
                  <q-item-label>{{ props.row.description }}</q-item-label>
                </q-item-section>
              </q-item>
              <q-item v-if="props.row.type_name == 'emailmkt'">
                <q-item-section>
                  <q-item-label class="text-weight-bold">{{ $t('subject') }}: </q-item-label>
                </q-item-section>
                <q-item-section>
                  <q-item-label>{{ props.row.subject }}</q-item-label>
                </q-item-section>
              </q-item>
              <q-item v-if="props.row.type_name == 'image'">
                <q-item-section>
                  <q-item-label>{{ $t('size') }}</q-item-label>
                </q-item-section>
                <q-item-section>
                  <q-item-label>{{ props.row.size }}</q-item-label>
                </q-item-section>
              </q-item>
              <q-item v-if="props.row.type_name == 'coupon'">
                <q-item-section>
                  <q-item-label class="text-weight-bold">{{ $t('code') }}:</q-item-label>
                </q-item-section>
                <q-item-section>
                  <q-item-label>{{ props.row.code }}</q-item-label>
                </q-item-section>
              </q-item>
              <q-item v-if="props.row.category">
                <q-item-section>
                  <q-item-label class="text-weight-bold">{{ $t('category') }}: </q-item-label>
                </q-item-section>
                <q-item-section>
                  <q-item-label>{{ props.row.category }}</q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <q-item-label class="text-weight-bold">{{ $t('activation_date') }}: </q-item-label>
                </q-item-section>
                <q-item-section>
                  <q-item-label
                    >{{ $moment(props.row.activation_date).locale($t('format_date')).format('L') }}
                  </q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <q-item-label class="text-weight-bold">{{ $t('expiration_date') }}: </q-item-label>
                </q-item-section>
                <q-item-section>
                  <q-item-label
                    >{{ $moment(props.row.expiration_date).locale($t('format_date')).format('L') }}
                  </q-item-label>
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  <q-item-label class="text-weight-bold">{{ $t('last_update') }}: </q-item-label>
                </q-item-section>
                <q-item-section>
                  <q-item-label
                    >{{ $moment(props.row.updated_at).locale($t('format_date')).format('L') }}
                  </q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-card-section>
          <q-card-section>
            <q-btn
              color="dark"
              no-caps
              :label="$t('generate_tag')"
              class="full-width q-mb-sm"
              @click="openGenerateTag(props.row)"
              unelevated
            />
          </q-card-section>
        </q-card>
      </div>
    </template>
    {{ channelId }}
    <template v-slot:bottom>
      <!-- <pagination-table
                @change="getCreative"
                :data="reqPagination"
                :pagination="pagination"
            /> -->
      <q-toolbar class="bg-transparent" :class="!$q.dark.isActive ? 'text-dark' : 'text-white'">
        Total:{{ reqPagination.total }}
        <q-space />
        <q-pagination
          unelevated
          v-model="currentPage"
          color="primary"
          :max="reqPagination.last_page"
          :max-pages="6"
          boundary-numbers
          direction-links
        />
      </q-toolbar>
    </template>
  </q-table>
</template>
<script>
import CreativeMixin from '../../mixins/CreativeMixin';

export default {
  name: 'ListCreative',
  mixins: [CreativeMixin],
  data() {
    return {
      pagination: {
        sortBy: 'created_at',
        descending: true,
        rowsPerPage: 12
      }
    };
  },

  computed: {
    currentPage: {
      get() {
        return this.reqPagination.current_page;
      },
      set(value) {
        this.ActionSetPagination({
          ...this.reqPagination,
          current_page: value
        });
      }
    }
  },

  watch: {
    currentPage() {
      if (this.reqPagination.from != this.reqPagination.current_page) {
        this.getCreativeSearch(`page=${this.currentPage}%26`, `perPage=${this.pagination.rowsPerPage}`);
      }
    }
  },

  methods: {
    openGenerateTag(creative) {
      this.$router.push({
        path: `/affiliate/tag/${creative.id}/${this.channelId}`
      });
    },

    getIconType(type) {
      switch (type) {
        case 'coupon':
          return 'fas fa-ticket-alt';
        case 'emailmkt':
          return 'fas fa-envelope';
        case 'deal':
          return 'fas fa-percentage';
        case 'hyperlink':
          return 'fas fa-file-alt';
        default:
          return 'fas fa-envelope';
      }
    }
  }
};
</script>

<style></style>
